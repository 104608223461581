/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReducer, useEffect, memo, useRef } from "react";
import type { ReactNode } from "react";
import { getMsg } from "keycloakify";
import { getCurrentKcLanguageTag } from "keycloakify";
import { useConstCallback } from "powerhooks/useConstCallback";
import type { KcTemplateProps } from "keycloakify";
import { makeStyles, Text } from "ui/theme";
import { useDomRect, useWindowInnerSize } from "onyxia-ui";
import { Alert } from "onyxia-ui/Alert";
import { headInsert } from "keycloakify/lib/tools/headInsert";
import type { KcContext } from "./kcContext";
import { symToStr } from "tsafe/symToStr";
import { pathJoin } from "keycloakify/lib/tools/pathJoin";
import { Select } from "@avidkit/select";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useOnScreen } from "ui/tools/useOnScreen";
import { detectDirection } from "ui/tools/detectDirection";
import { getReverseMessage } from "ui/extendKcMessages/getReverseMessage";
import { Link } from "@mui/material";

export type ErrorProps = {
    className?: string;
    displayInfo?: boolean;
    displayMessage?: boolean;
    displayRequiredFields?: boolean;
    displayWide?: boolean;
    showAnotherWayIfPresent?: boolean;
    showUsernameNode?: ReactNode;
    infoNode?: ReactNode;
} & { kcContext: KcContext } & KcTemplateProps;

const useStyles = makeStyles<{
    windowInnerWidth: number;
    aspectRatio: number;
    windowInnerHeight: number;
}>()(() => ({
    "root": {
        "height": "100vh",
        "display": "flex",
        "flexDirection": "column",
        "backgroundColor": "#f9f9f9",
    },
    "betweenHeaderAndFooter": {
        "flex": 1,
        "overflow": "hidden",
        "background": `#f9f9f9`,
    },
    "page": {
        "height": "100%",
        "overflow": "auto",
    },
}));

export const Error = memo((props: ErrorProps) => {
    const { kcContext, className } = props;

    const { ref: rootRef } = useDomRect();

    const { windowInnerWidth, windowInnerHeight } = useWindowInnerSize();

    const { classes, cx } = useStyles({
        windowInnerWidth,
        "aspectRatio": windowInnerWidth / windowInnerHeight,
        windowInnerHeight,
    });

    const [isExtraCssLoaded, setExtraCssLoaded] = useReducer(() => true, false);

    useEffect(() => {
        let isUnmounted = false;
        const cleanups: (() => void)[] = [];

        const toArr = (x: string | readonly string[] | undefined) =>
            typeof x === "string" ? x.split(" ") : x ?? [];

        Promise.all(
            [
                ...toArr(props.stylesCommon).map(relativePath =>
                    pathJoin(kcContext.url.resourcesCommonPath, relativePath),
                ),
                ...toArr(props.styles).map(relativePath =>
                    pathJoin(kcContext.url.resourcesPath, relativePath),
                ),
            ].map(href =>
                headInsert({
                    "type": "css",
                    href,
                    "position": "prepend",
                }),
            ),
        ).then(() => {
            if (isUnmounted) {
                return;
            }

            setExtraCssLoaded();
        });

        toArr(props.scripts).forEach(relativePath =>
            headInsert({
                "type": "javascript",
                "src": pathJoin(kcContext.url.resourcesPath, relativePath),
            }),
        );

        if (props.kcHtmlClass !== undefined) {
            const htmlClassList = document.getElementsByTagName("html")[0].classList;

            const tokens = cx(props.kcHtmlClass).split(" ");

            htmlClassList.add(...tokens);

            cleanups.push(() => htmlClassList.remove(...tokens));
        }

        return () => {
            isUnmounted = true;

            cleanups.forEach(f => f());
        };
    }, [props.kcHtmlClass]);

    if (!isExtraCssLoaded) {
        return null;
    }

    return (
        <div ref={rootRef} className={cx(classes.root, className)}>
            <section className={classes.betweenHeaderAndFooter}>
                <Page {...props} className={classes.page} />
            </section>
        </div>
    );
});

const { Page } = (() => {
    type Props = {
        className?: string;
        displayInfo?: boolean;
        displayMessage?: boolean;
        displayRequiredFields?: boolean;
        displayWide?: boolean;
        showAnotherWayIfPresent?: boolean;
        showUsernameNode?: ReactNode;
        infoNode?: ReactNode;
    } & { kcContext: KcContext } & KcTemplateProps;

    const Page = memo((props: Props) => {
        const {
            className,
            displayInfo = false,
            displayMessage = true,
            displayRequiredFields = false,
            displayWide = false,
            showAnotherWayIfPresent = true,
            showUsernameNode = null,
            infoNode = null,
            kcContext,
            ...kcProps
        } = props;

        console.log("##############", kcContext, "#############");

        const supportedLanguageTagUrl: Record<string, string> = {};
        const supportedLanguageTags: string[] = [];
        kcContext.locale?.supported.forEach(s => {
            supportedLanguageTagUrl[s.languageTag] = s.url;
            supportedLanguageTags.push(s.languageTag);
        });

        const currentLanguageTag = getCurrentKcLanguageTag(kcContext);

        const direction = detectDirection(kcContext);

        const isBigWidth = useMediaQuery("(min-width: 600px)");

        const {
            ref: containerRef,
            domRect: { height: containerHeight },
        } = useDomRect();
        const {
            ref: paperRef,
            domRect: { height: paperHeight },
        } = useDomRect();

        const { classes, cx } = useStyles({
            "isPaperBiggerThanContainer": paperHeight > containerHeight,
            "isBigWidth": isBigWidth,
        });

        const headerRef = useRef<HTMLDivElement>(null);
        const isHeaderOnScreen = useOnScreen(headerRef);

        return (
            <div ref={containerRef} className={cx(classes.root, className)}>
                <div ref={paperRef} className={classes.paper}>
                    <div
                        style={{
                            display: "flex",
                            height: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: isBigWidth ? `100%` : "100%",
                            }}
                        >
                            <div
                                className={classes.leftSectionHeaderWrapper}
                                style={{
                                    boxShadow: isHeaderOnScreen
                                        ? undefined
                                        : "0 2px 12px 0 rgba(188, 189, 191, 0.7)",
                                    zIndex: 10,
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        width: "60px",
                                    }}
                                ></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "100%",
                                        paddingRight: "24px",
                                        direction,
                                    }}
                                >
                                    {kcContext.realm.internationalizationEnabled && (
                                        <Select
                                            items={supportedLanguageTags}
                                            selectedItems={[currentLanguageTag]}
                                            onSelect={languageTag => {
                                                window.location.replace(
                                                    window.location.origin +
                                                        supportedLanguageTagUrl[
                                                            languageTag
                                                        ],
                                                );
                                            }}
                                            className={classes.languageSelect}
                                        />
                                    )}
                                </div>
                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "calc(100% - 72px)",
                                    overflowY: "auto",
                                    padding: "0 24px",
                                }}
                                className={"withoutScrollbar"}
                            >
                                <div
                                    style={
                                        isBigWidth
                                            ? { minWidth: "387px" }
                                            : {
                                                  width: "100%",
                                              }
                                    }
                                >
                                    <Head
                                        {...{ kcContext, ...kcProps }}
                                        displayRequiredFields={displayRequiredFields}
                                        showUsernameNode={showUsernameNode}
                                        headerRef={headerRef}
                                        style={{ direction, display: "flex" }}
                                    />
                                    <Main
                                        {...{ kcContext, ...kcProps }}
                                        displayMessage={displayMessage}
                                        showAnotherWayIfPresent={showAnotherWayIfPresent}
                                        displayWide={displayWide}
                                        displayInfo={displayInfo}
                                        infoNode={infoNode}
                                        style={{
                                            direction,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    const useStyles = makeStyles<{
        isPaperBiggerThanContainer?: boolean;
        isBigWidth?: boolean;
    }>({
        "name": { Page },
    })((theme, { isPaperBiggerThanContainer, isBigWidth }) => ({
        "root": {
            "display": "flex",
            "justifyContent": "center",
            "alignItems": isPaperBiggerThanContainer ? undefined : "center",
        },
        "paper": {
            "width": isBigWidth ? `600px` : "100%",
            "height": isBigWidth ? `250px` : "100%",
            "backgroundColor": "white",
            "marginBottom": theme.spacing(4),
            "borderRadius": "1px",
            "boxShadow": "0 10px 30px 0 rgba(123, 129, 139, 0.3)",
        },
        "alert": {
            "alignItems": "center",
        },
        "leftSectionHeaderWrapper": {
            "display": "flex",
            "height": "72px",
            "alignItems": "center",
        },
        "languageSelect": {
            "width": "50%",
            "maxWidth": "200px",
            "direction": "ltr",
        },
    }));

    const { Head } = (() => {
        type Props = {
            displayRequiredFields: boolean;
            showUsernameNode?: ReactNode;
            style?: React.CSSProperties;
            headerRef?: React.RefObject<HTMLDivElement>;
        } & { kcContext: KcContext } & KcTemplateProps;

        const Head = memo((props: Props) => {
            const {
                kcContext,
                displayRequiredFields,
                showUsernameNode,
                style,
                headerRef,
                ...kcProps
            } = props;

            const { msg, msgStr } = getMsg(kcContext);

            const { classes, cx } = useStyles();

            return (
                <header style={style} ref={headerRef}>
                    {!(
                        kcContext.auth !== undefined &&
                        kcContext.auth.showUsername &&
                        !kcContext.auth.showResetCredentials
                    ) ? (
                        displayRequiredFields ? (
                            <div className={cx(kcProps.kcContentWrapperClass)}>
                                <div
                                    className={cx(
                                        kcProps.kcLabelWrapperClass,
                                        "subtitle",
                                    )}
                                >
                                    <span className="subtitle">
                                        <span className="required">*</span>
                                        {msg("requiredFields")}
                                    </span>
                                </div>
                                <div className="col-md-10">
                                    <Text className={classes.root} typo="section heading">
                                        {msgStr("errorTitle")}
                                    </Text>
                                </div>
                            </div>
                        ) : (
                            <Text className={classes.root} typo="section heading">
                                {msgStr("errorTitle")}
                            </Text>
                        )
                    ) : displayRequiredFields ? (
                        <div className={cx(kcProps.kcContentWrapperClass)}>
                            <div className={cx(kcProps.kcLabelWrapperClass, "subtitle")}>
                                <span className="subtitle">
                                    <span className="required">*</span>{" "}
                                    {msg("requiredFields")}
                                </span>
                            </div>
                            <div className="col-md-10">
                                {showUsernameNode}
                                <div className={cx(kcProps.kcFormGroupClass)}>
                                    <div id="kc-username">
                                        <label id="kc-attempted-username">
                                            {kcContext.auth?.attemptedUsername}
                                        </label>
                                        <a
                                            id="reset-login"
                                            href={kcContext.url.loginRestartFlowUrl}
                                        >
                                            <div className="kc-login-tooltip">
                                                <i
                                                    className={cx(
                                                        kcProps.kcResetFlowIcon,
                                                    )}
                                                ></i>
                                                <span className="kc-tooltip-text">
                                                    {msg("restartLoginTooltip")}
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {showUsernameNode}
                            <div className={cx(kcProps.kcFormGroupClass)}>
                                <div id="kc-username">
                                    <label id="kc-attempted-username">
                                        {kcContext.auth?.attemptedUsername}
                                    </label>
                                    <a
                                        id="reset-login"
                                        href={kcContext.url.loginRestartFlowUrl}
                                    >
                                        <div className="kc-login-tooltip">
                                            <i
                                                className={cx(kcProps.kcResetFlowIcon)}
                                            ></i>
                                            <span className="kc-tooltip-text">
                                                {msg("restartLoginTooltip")}
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </header>
            );
        });

        const useStyles = makeStyles({
            "name": `${symToStr({ Error })}${symToStr({ Head })}`,
        })(() => ({
            "root": {
                "textAlign": "center",
                "marginBottom": "6px",
            },
        }));

        return { Head };
    })();

    const { Main } = (() => {
        type Props = {
            displayMessage?: boolean;
            showAnotherWayIfPresent?: boolean;
            displayWide?: boolean;
            displayInfo?: boolean;
            infoNode?: ReactNode;
            style?: React.CSSProperties;
        } & { kcContext: KcContext } & KcTemplateProps;

        const Main = memo((props: Props) => {
            const {
                displayMessage,
                showAnotherWayIfPresent,
                displayInfo,
                displayWide,
                kcContext,
                infoNode,
                style,
                ...kcProps
            } = props;

            const onTryAnotherWayClick = useConstCallback(() => {
                document.forms["kc-select-try-another-way-form" as never].submit();
                return false;
            });

            const { msg, advancedMsg } = getMsg(kcContext);

            const { classes, cx } = useStyles();

            console.log("message", kcContext.message?.summary, kcContext.message?.type);

            return (
                <div id="kc-content">
                    <div id="kc-content-wrapper">
                        {/* App-initiated actions should not see warning messages about the need to complete the action during login.*/}
                        {kcContext.message !== undefined &&
                            (kcContext.message.type !== "warning" ||
                                !kcContext.isAppInitiatedAction) && (
                                <div style={style}>
                                    <Alert
                                        className={classes.alert}
                                        severity={kcContext.message.type}
                                    >
                                        <Text typo="label 2">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    "__html": getReverseMessage(
                                                        kcContext,
                                                        kcContext.message.summary,
                                                    ),
                                                }}
                                            />
                                        </Text>
                                    </Alert>
                                </div>
                            )}
                        {
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "10px",
                                }}
                            >
                                <Link
                                    href={kcContext.url.loginUrl}
                                    className={classes.loginLink}
                                    underline="hover"
                                >
                                    {advancedMsg("backToApplication")}
                                </Link>
                            </div>
                        }
                        {kcContext.auth !== undefined &&
                            kcContext.auth.showTryAnotherWayLink &&
                            showAnotherWayIfPresent && (
                                <form
                                    id="kc-select-try-another-way-form"
                                    action={kcContext.url.loginAction}
                                    method="post"
                                    className={cx(
                                        displayWide && props.kcContentWrapperClass,
                                    )}
                                >
                                    <div
                                        className={cx(
                                            displayWide && [
                                                kcProps.kcFormSocialAccountContentClass,
                                                kcProps.kcFormSocialAccountClass,
                                            ],
                                        )}
                                    >
                                        <div className={cx(kcProps.kcFormGroupClass)}>
                                            <input
                                                type="hidden"
                                                name="tryAnotherWay"
                                                value="on"
                                            />
                                            <a
                                                href="#"
                                                id="try-another-way"
                                                onClick={onTryAnotherWayClick}
                                            >
                                                {msg("doTryAnotherWay")}
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            )}
                        {displayInfo && (
                            <div id="kc-info" className={cx(kcProps.kcSignUpClass)}>
                                <div
                                    id="kc-info-wrapper"
                                    className={cx(kcProps.kcInfoAreaWrapperClass)}
                                >
                                    {infoNode}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        });

        const useStyles = makeStyles({
            "name": `${symToStr({ Error })}${symToStr({ Main })}`,
        })(() => ({
            "alert": {
                "alignItems": "center",
            },
            "loginLink": {
                "color": "#09b6c7",
            },
        }));

        return { Main };
    })();

    return { Page };
})();
