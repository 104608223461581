import * as reactDom from "react-dom";
import { I18nProvider } from "./i18n/I18nProvider";
import { RouteProvider } from "./routes";
import { CoreProvider } from "ui/coreApi/CoreProvider";
import { ThemeProvider as OnyxiaThemeProvider, createGetViewPortConfig } from "./theme";
import { App } from "ui/components/App";
import { kcContext } from "ui/components/KcApp/kcContext";
import { KcApp } from "ui/components/KcApp/KcApp";
import { PortraitModeUnsupported } from "ui/components/pages/PortraitModeUnsupported";
import "./envCarriedOverToKc";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "styled-components";
import "./extendKcMessages";
import { getConfig } from "config/batch";

const { getViewPortConfig } = createGetViewPortConfig({ PortraitModeUnsupported });

const theme = createTheme({
    palette: {
        ...getConfig(kcContext!).palette.main,
    },
});

const LogContext = () => {
    console.log("Current kcContext during render:", kcContext);
    return null;
};

reactDom.render(
    <I18nProvider>
        <RouteProvider>
            <LogContext />
            <OnyxiaThemeProvider
                getViewPortConfig={
                    kcContext !== undefined ? undefined : getViewPortConfig
                }
            >
                <ThemeProvider theme={theme}>
                    {kcContext !== undefined ? (
                        <KcApp kcContext={kcContext} />
                    ) : (
                        <CoreProvider>
                            <App />
                        </CoreProvider>
                    )}
                </ThemeProvider>
            </OnyxiaThemeProvider>
        </RouteProvider>
    </I18nProvider>,
    document.getElementById("root"),
);
